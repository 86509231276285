import { ConversationalWidgetDataType } from './view/conversational-card/types/ConversationalCardTypes';

export const conversationalData: ConversationalWidgetDataType[] = [
   {
      label: 'step 1 of 2',
      heading: 'How many devices are you looking to connect to your wi-fi?',
      subHeading: 'laptops, TVs, mobiles',
      conversationType: 'noOfDevices',
      buttons: [
         {
            id: 1,
            text: '1-5',
            value: 1,
            buttonType: 'badge',
         },
         {
            id: 2,
            text: '6-10',
            value: 2,
            buttonType: 'badge',
         },
         {
            id: 3,
            text: '10+',
            value: 3,
            buttonType: 'badge',
         },
         // to be confirmed by product
         //  {
         //     id: 4,
         //     text: '20+',
         //     value: 4,
         //     buttonType: 'badge',
         //  },
      ],
   },
   {
      label: 'step 2 of 2',
      heading: 'What do you plan to use the connection for?',
      subHeading: 'select all that apply',
      conversationType: 'usage',
      buttons: [
         {
            id: 1,
            text: 'General Browsing',
            buttonType: 'badge',
            value: {
               1: 25,
               2: 25,
               3: 25,
               4: 25,
            },
         },
         {
            id: 2,
            text: 'Gaming',
            buttonType: 'badge',
            value: {
               1: 25,
               2: 40,
               3: 80,
               4: 80,
            },
         },
         {
            id: 3,
            text: 'Netflix, Hotstar etc.',
            buttonType: 'badge',
            value: {
               1: 25,
               2: 40,
               3: 80,
               4: 80,
            },
         },
         {
            id: 4,
            text: 'Work from home',
            buttonType: 'badge',
            value: {
               1: 40,
               2: 60,
               3: 110,
               4: 110,
            },
         },
      ],
   },
   {
      label: 'The best plan for you is',
      heading: '300 mbps',
      subHeading: 'Rs. 1499 per month',
      conversationType: 'result',
      buttons: [
         {
            id: 1,
            text: 'Get Started',
            buttonType: 'button',
            value: -1,
            link: 'https://www.airtel.in',
         },
         {
            id: 2,
            text: 'VIEW OTHER PLANS',
            buttonType: 'link',
            value: -1,
            link: 'https://www.airtel.in/plans/broadband?icid=header',
         },
      ],
   },
];
