import React, { FC, useEffect, useRef, useState } from 'react';
import {
   useGetMediaBreakpoint,
   GridColumn,
   GridRow,
   Slider,
   SliderRefTypes,
   Typography,
   SlideDataTypes,
   SliderOnChangeTypes,
} from '@airtel-web/legos';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import ConversationalCard from './view/conversational-card/ConversationalCard';
import ResultCard from './view/result-card/ResultCard';
import { conversationalData } from './ConversationalWidgetData';
import { ConversationalDataFormType } from './types/ConversationalWidgetTypes';
import {
   ConversationalWidgetDataButton,
   ConversationalWidgetDataType,
} from './view/conversational-card/types/ConversationalCardTypes';
import {
   calculateSpeedAndPrice,
   sliderBreakpoints,
   updateConversationalDataStata,
} from './ConversationalWidgetUtils';
import { SliderDot } from '../../../../common/assets/icons/SliderDot';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import './ConversationalWidget.scss';

const ConversationalWidget: FC = () => {
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isSmallDevice = mediaBreakpoint === 'xs';
   const [showGradient, setShowGradient] = useState<boolean>(true);
   const [currentSlide, setCurrentSlide] = useState<number>(-1);
   const [conversationalDataState, setConversationalDataState] =
      useState<ConversationalWidgetDataType[]>(conversationalData);
   const [conversationalDataForm, setConversationalDataForm] =
      useState<ConversationalDataFormType>({
         noOfDevices: isMobile ? 0 : 1,
         usage: isMobile ? [] : [conversationalData[1]?.buttons[0], conversationalData[1]?.buttons[1]],
      });
   const slider = useRef<SliderRefTypes>(null);

   useEffect(() => {
      if (!isMobile) { 
         const { recommendedSpeed, price } = calculateSpeedAndPrice(conversationalDataForm);
         setConversationalDataState(
            updateConversationalDataStata(conversationalData, recommendedSpeed, price)
         );
         if (slider.current?.nextSlide) {
            slider.current.nextSlide();
            slider.current.nextSlide();
         }
      }
   }, [isMobile]);

   const onConversationalOptionClick = (
      conversationType: string,
      buttonData: ConversationalWidgetDataButton
   ) => {
      if (conversationType === 'noOfDevices') {
         onNoOfDevicesClickHandler(buttonData?.value);
      } else if (conversationType === 'usage') {
         let newConversationalDataForm: ConversationalDataFormType = {
            noOfDevices: conversationalDataForm.noOfDevices,
            usage: [],
         };
         if (conversationalDataForm.usage.includes(buttonData)) {
            newConversationalDataForm = {
               ...conversationalDataForm,
               usage: conversationalDataForm.usage.filter(
                  (usageData) => usageData !== buttonData
               ),
            };
         } else {
            newConversationalDataForm = {
               ...conversationalDataForm,
               usage: [...conversationalDataForm.usage, buttonData],
            };
         }
         setConversationalDataForm(newConversationalDataForm);
   
         if (isMobile) {
            if (currentSlide === 0 && slider.current?.nextSlide) {
               slider.current.nextSlide();
            } else if (currentSlide === 1 && slider.current?.nextSlide) {
               slider.current.nextSlide();
               slider.current.nextSlide();
            }
         } else {
            if (newConversationalDataForm.usage.length === 0) {
               moveToPrevious();
            } else {
               moveToResult(newConversationalDataForm);
            }
         }
      }
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: buttonData?.text?.toLowerCase(),
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '9',
      });
   };

   const onNoOfDevicesClickHandler = (btnValue: number) => {
      const newConversationalDataForm = {
         ...conversationalDataForm,
         noOfDevices: btnValue,
      };
      setConversationalDataForm(newConversationalDataForm);
      setShowGradient(false);
      if (slider.current?.nextSlide && isMobile) {
         slider.current.nextSlide();
      }
      if (!isMobile && conversationalDataForm.usage.length > 0) {
         moveToResult(newConversationalDataForm);
      }
   };

   const moveToPrevious = () => {
      if (slider.current?.prevSlide) slider.current.prevSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.arrow,
         eventValue: '',
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '9',
      });
   };

   const moveToResult = (data?: ConversationalDataFormType) => {
      if (data && !data.noOfDevices) {
         data = undefined;
      }
      const { recommendedSpeed, price } = calculateSpeedAndPrice(
         data || conversationalDataForm
      );
      setConversationalDataState(
         updateConversationalDataStata(conversationalData, recommendedSpeed, price)
      );
      if (slider.current?.nextSlide) slider.current.nextSlide();
      fireAnalytics({
         section: ANALYTICS_CONSTANTS.whatSpeedDoINeed,
         eventAction: ANALYTICS_CONSTANTS.click,
         eventLabel: ANALYTICS_CONSTANTS.arrow,
         eventValue: '',
         customLabel: ANALYTICS_CONSTANTS.button,
         isInteractive: '1',
         horizontalPosition: '0',
         verticalPosition: '9',
      });
   };

   const onSliderChange = (e: SliderOnChangeTypes) => {
      if (currentSlide !== e.currIndex) {
         setCurrentSlide(e.currIndex);
      }
   };

   const slides: SlideDataTypes[] = [
      {
         children: <div className="conversation-card"></div>,
      },
      {
         children: (
            <ConversationalCard
               conversationalData={conversationalDataState[0]}
               conversationalDataForm={conversationalDataForm}
               showGradient={showGradient}
               onConversationalOptionClick={onConversationalOptionClick}
               moveToPrevious={moveToPrevious}
               moveToResult={moveToResult}
            />
         ),
      },
      {
         children: (
            <ConversationalCard
               conversationalData={conversationalDataState[1]}
               conversationalDataForm={conversationalDataForm}
               showGradient={isMobile ? showGradient : false}
               onConversationalOptionClick={onConversationalOptionClick}
               moveToPrevious={moveToPrevious}
               moveToResult={moveToResult}
            />
         ),
      },
      {
         children: (
            <ResultCard
               conversationalData={conversationalDataState[2]}
               currentSlide={currentSlide}
               moveToPrevious={moveToPrevious}
            />
         ),
      },
   ];

   return (
      <div className="conversational-widget-root icon-button-black800">
         <GridRow direction="row-as-column" fullWidth={isMobile}>
            <GridColumn
               noGutter
               alignSelf="center"
               className="conversational-widget-heading-container"
            >
               <Typography
                  type={isSmallDevice ? 'body-single-line-lg-bold' : 'h4-bold'}
                  color={colors.black100}
                  className="conversational-widget-heading"
               >
                  Find the best Wi-Fi plan for you
               </Typography>
            </GridColumn>
            <GridColumn noGutter>
               {slides.length > 0 && (
                  <Slider
                     ref={slider}
                     breakpoints={sliderBreakpoints}
                     data={slides}
                     defaultSlide={1}
                     dots={{
                        clickable: false,
                        numberOfDots: 4,
                        show: isMobile,
                        customDot: <SliderDot fill="none" />,
                        activeCustomDot: <SliderDot fill={colors.black900} />,
                     }}
                     slideTransitionSpeed={300}
                     onSlideChangeHandler={onSliderChange}
                  />
               )}
            </GridColumn>
         </GridRow>
      </div>
   );
};

export default ConversationalWidget;
