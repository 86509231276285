export const ENV_NAME = {
   PROD: 'PROD',
   QA: 'QA',
   DEV: 'DEV',
};

export const API_MAPPING = {
   PROD: 'https://digi-api.airtel.in/',
   STAGE: 'https://digi-api.airtel.in/'
};
