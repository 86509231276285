export const SECTIONS_ENUM = {
   ANNOUNCEMENT_BAR_SECTION: 'announcement-bar-section',
   HERO_BANNER_SECTION: 'hero-banner-section',
   RECHARGE_SECTION: 'recharge-section',
   OFFER_SECTION: 'offer-section',
   B2B_SECTION: 'b2b-section',
   HEADER_SECTION: 'header-section',
   SPEED_GUIDE_SECTION: 'speed-guide-section',
   SWITCH_TO_AIRTEL_SECTION: 'switch-to-airtel-section',
   SUBSCRIPTIONS_SECTION: 'subscriptions-section',
   RECOMMENDATIONS_SECTION: 'recommendations-section',
   SANCHAR_SAATHI_SECTION: 'sanchar-saathi-section',
};
