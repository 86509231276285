import React, { useEffect } from 'react';
import {
   Button,
   GridRow,
   GridColumn,
   Typography,
   Image,
   useGetMediaBreakpoint,
} from '@airtel-web/legos';
import BuyNewConnection from './view/buy-new-connection/BuyNewConnection';
import { SwitchToAirtelPropTypes } from './types/SwitchToAirtelPropTypes';
import { navigateTo } from '../../../../utils/utils';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import colors from '@airtel-web/legos/lib/styles/variables/colors.module.scss';
import './SwitchToAirtel.scss';

const SwitchToAirtel = (props: SwitchToAirtelPropTypes) => {
   const mediaBreakpoint = useGetMediaBreakpoint();
   const isMobile = mediaBreakpoint === 'xs' || mediaBreakpoint === 'sm';
   const isSmallDevice = mediaBreakpoint === 'xs';

   const ctaEvents = {
      section: ANALYTICS_CONSTANTS.buyNewConnection,
      eventAction: ANALYTICS_CONSTANTS.impression,
      eventLabel: ANALYTICS_CONSTANTS.switchToAirtel,
      eventValue: ANALYTICS_CONSTANTS.selectAPlan,
      customLabel: ANALYTICS_CONSTANTS.button,
      isInteractive: '0',
      horizontalPosition: '6',
      verticalPosition: '5',
   };

   useEffect(() => {
      fireAnalytics(ctaEvents, 'buyNewConnection');
   }, []);

   const GetStartedClick = () => {
      fireAnalytics({
         ...ctaEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         customValue: '',
         isInteractive: '1',
      });
      navigateTo('https://www.airtel.in/new-connection/postpaid-sim/new-sim?utm_source=website&utm_campaign=web-buynew&icid=quick_action');
   };

   return (
      <div className="switch-to-airtel-root">
         <GridRow direction="row-as-column" className="switch-to-airtel-inner" noMargin>
            <GridColumn
               noGutter
               alignSelf="center"
               className="switch-to-airtel-heading"
               layout="comfortable"
            >
               <Typography
                  type={isSmallDevice ? 'body-single-line-lg-bold' : 'h4-bold'}
                  color={colors.black900}
               >
                  Buy a new connection
               </Typography>
            </GridColumn>
            {props.switchToAirtelData && (
               <GridColumn noGutter layout="comfortable">
                  <BuyNewConnection linksCmsData={props.switchToAirtelData} />
               </GridColumn>
            )}
         </GridRow>
         <GridRow
            className="switch-your-number"
            justifyContent="center"
            id="buyNewConnection"
         >
            <Image
               src="https://assets.airtel.in/static-assets/home-recast/images/switch-to-airtel-web.png"
               className="switch-image"
               draggable={false}
               alt="switch to airtel image"
            />
            <GridRow noMargin className="switch-to-airtel-column">
               <GridColumn noGutter breakpoints={['col-sm-12', 'col-md-8']}>
                  <GridRow
                     noMargin
                     direction="row-as-column"
                     className="switch-to-airtel-content"
                  >
                     <GridColumn noGutter className="switch-text">
                        <Typography
                           type={
                              isMobile
                                 ? 'body-single-line-xs-bold'
                                 : 'body-single-line-md-bold'
                           }
                           color={colors.black800}
                        >
                           Switch to airtel
                        </Typography>
                     </GridColumn>
                     <GridColumn noGutter className="get-sim-text">
                        <Typography
                           type={isMobile ? 'body-para-xxs' : 'body-single-line-xs'}
                           color={colors.black800}
                        >
                           Select a plan & order SIM &bull; Get SIM home-delivered
                        </Typography>
                     </GridColumn>
                  </GridRow>
               </GridColumn>
               <GridColumn noGutter breakpoints={['col-sm-12', 'col-md-4']}>
                  <GridRow noMargin justifyContent={isMobile ? 'unset' : 'end'}>
                     <Button
                        data-testid="switchToAirtel-button"
                        buttonType="alternate-light"
                        className="get-started-btn"
                        onClick={GetStartedClick}
                     >
                        GET STARTED
                     </Button>
                  </GridRow>
               </GridColumn>
            </GridRow>
         </GridRow>
      </div>
   );
};

export default SwitchToAirtel;
