import React, { FC, useEffect, useState } from 'react';
import { GridRow, Image, GridColumn, useGetMediaBreakpoint } from '@airtel-web/legos';
import { SancharSectionPropTypes } from './types/SancharProtoTypes';
import { STATIC_ASSETS_URL } from '../../../../common/constants/AppConstants';
import { fireAnalytics } from '../../service/HomePageAnalyticsService';
import { ANALYTICS_CONSTANTS } from '../../../../common/constants/AnalyticsConstants';
import './SancharSection.scss';

const SancharSection: FC<SancharSectionPropTypes> = (props) => {
   const { sectionCmsData } = props;
   const ctaLink =
      sectionCmsData?.widgetContainers?.[0]?.staticData?.dataAttributes?.ctaLink || '';
   const [isMobile, setIsMobile] = useState<boolean>(false);
   const mediaBreakpoint = useGetMediaBreakpoint();
   const imgDesktop: string =
      sectionCmsData?.widgetContainers?.[0]?.staticData?.imageUrl?.desktop ||
      `${STATIC_ASSETS_URL}/images/dot_sanchar_sathi_banner_dweb.jpg`;
   const imgDataMobile: string =
      sectionCmsData?.widgetContainers?.[0]?.staticData?.imageUrl?.mobile ||
      `${STATIC_ASSETS_URL}/images/dot_sanchar_sathi_banner_mweb.jpg`;

   const ctaText =
      sectionCmsData?.widgetContainers?.[0]?.staticData?.dataAttributes?.ctaLink || '';
   const sancharCtaEvents = {
      section: ANALYTICS_CONSTANTS.sancharSaathi,
      eventLabel: ctaText?.toLowerCase(),
      isInteractive: '0',
      horizontalPosition: '0',
      verticalPosition: '0',
   };

   useEffect(() => {
      fireAnalytics(sancharCtaEvents, 'sancharSaathi');
   }, []);

   const onCtaClick = () => {
      fireAnalytics({
         ...sancharCtaEvents,
         eventAction: ANALYTICS_CONSTANTS.click,
         isInteractive: '1',
         customValue: '',
      });
   };

   useEffect(() => {
      setIsMobile(mediaBreakpoint === 'xs');
   }, [mediaBreakpoint]);

   return (
      <div className="sanchar-saathi">
         <GridRow justifyContent="center" direction="row-as-column">
            <GridColumn>
               <a href={ctaLink} target="_blank" rel="noreferrer" onClick={onCtaClick}>
                  <Image
                     alt={`sanchar saathi ${isMobile ? 'mobile' : 'desktop'} banner`}
                     src={isMobile ? imgDataMobile : imgDesktop}
                     width="100%"
                  />
               </a>
            </GridColumn>
         </GridRow>
      </div>
   );
};

export default SancharSection;
